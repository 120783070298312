import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import axios from 'axios';
import './MapView.css';
import { typeLine } from './Printer';
import { Mutex } from "async-mutex";

const mapStyles = { 
  height: '400px', 
  width: '100%', 
  borderRadius: '10px', 
  filter: 'grayscale(100%) brightness(0.6) contrast(1.5)', // Give it a greenish feel
  border: '2px solid #33ff33', 
  pointerEvents: 'none'
};

const mutex = new Mutex();
const MapView = () => {
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0, updated: 0 });
  const [lastUpdated, setLastUpdated] = useState('');
  const [lastFetched, setLastFetched] = useState(0);
  const [address, setAddress] = useState('');
    
  const fetchCoordinates = async () => {
    try {
      if (lastFetched && (new Date() - lastFetched < 30000)){
        return;
      }
      else{
        await setLastFetched(new Date());
        const response = await axios.get('/api/coordinates');  
        await setCoordinates({lat: response.data.latitude, lng: response.data.longitude, updated: response.data.updated});
        await updateAddress(response.data.address.formatted_address);
        await updateLastUpdated(response.data.updated);  
      } 
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  }

  useEffect(() => {
      setupTvNoise();
      if (mutex.isLocked()) return;
      mutex.acquire().then(function(release){
        fetchCoordinates();
        release();
      })

      const interval = setInterval(()=>fetchCoordinates(), 30000);
      return () => clearInterval(interval);
  }, []);

  const updateAddress = async (address) => {
    if (mutex.isLocked()) return;
    let release = await mutex.acquire();
    
    // if (address == coordinates.address) {
    //     return;
    // }
    setAddress('');
    await typeLine(address, setAddress);
    release();
  }

  const updateLastUpdated = async (updated) => {
    if (mutex.isLocked()) return;
    let release = await mutex.acquire();
    
    if (updated == coordinates.updated) {
        return;
    }
    setLastUpdated('');
    let text = `Last updated: ${updated} UTC`;
    await typeLine(text, setLastUpdated);
    release();
  }


  
  return (
    <div>
      {
      coordinates.updated != 0 ? 
        <div>
          <h2>LOCATION ACQUIRED</h2>
          <LoadScript googleMapsApiKey="AIzaSyDNQBHIu8nm5D05t_SsZAtcw1DlF8byN68">
            <div className='mapview'>
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={15}
                center={coordinates}
              >
                <MarkerF position={coordinates} />
              </GoogleMap>
              <canvas id="tv"></canvas>
              <div id="glass"></div>
            </div>
          </LoadScript>
          <div>{address}</div>
          <div>{lastUpdated}</div>
        </div>
        : <h2>LOCATION NOT FOUND</h2>
      }
    </div>
    
  );
};

export default MapView;


function setupTvNoise() {
  setTimeout(() => {
    (function () {
      "use strict";

      var canvas = document.querySelector("#tv");
      if (!canvas) return;
      let context = canvas.getContext("gl") || canvas.getContext("2d"), 
        scaleFactor = 3.5, // Noise size
        samples = [], sampleIndex = 0, scanOffsetY = 0, 
        scanSize = 0, 
        FPS = 30, 
        scanSpeed = FPS * 15, // 15 seconds from top to bottom
        SAMPLE_COUNT = 10;

      window.onresize = function () {
        canvas.width = canvas.offsetWidth / scaleFactor;
        canvas.height = canvas.width / (canvas.offsetWidth / canvas.offsetHeight);
        scanSize = (canvas.offsetHeight / scaleFactor) / 3;

        samples = [];
        for (var i = 0; i < SAMPLE_COUNT; i++)
          samples.push(generateRandomSample(context, canvas.width, canvas.height));
      };

      function interpolate(x, x0, y0, x1, y1) {
        return y0 + (y1 - y0) * ((x - x0) / (x1 - x0));
      }


      function generateRandomSample(context, w, h) {
        var intensity = [];
        var random = 0;
        var factor = h / 50;
        var trans = 1 - Math.random() * 0.05;

        var intensityCurve = [];
        for (var i = 0; i < Math.floor(h / factor) + factor; i++)
          intensityCurve.push(Math.floor(Math.random() * 15));

        for (var i = 0; i < h; i++) {
          var value = interpolate((i / factor), Math.floor(i / factor), intensityCurve[Math.floor(i / factor)], Math.floor(i / factor) + 1, intensityCurve[Math.floor(i / factor) + 1]);
          intensity.push(value);
        }

        var imageData = context.createImageData(w, h);
        for (var i = 0; i < (w * h); i++) {
          var k = i * 4;
          var color = Math.floor(36 * Math.random());
          // Optional: add an intensity curve to try to simulate scan lines
          color += intensity[Math.floor(i / w)];
          imageData.data[k] = imageData.data[k + 1] = imageData.data[k + 2] = color;
          imageData.data[k + 3] = Math.round(255 * trans);
        }
        return imageData;
      }

      function render() {
        context.putImageData(samples[Math.floor(sampleIndex)], 0, 0);

        sampleIndex += 20 / FPS; // 1/FPS == 1 second
        if (sampleIndex >= samples.length) sampleIndex = 0;

        var grd = context.createLinearGradient(0, scanOffsetY, 0, scanSize + scanOffsetY);

        grd.addColorStop(0, 'rgba(255,255,255,0)');
        grd.addColorStop(0.1, 'rgba(255,255,255,0)');
        grd.addColorStop(0.2, 'rgba(255,255,255,0.2)');
        grd.addColorStop(0.3, 'rgba(255,255,255,0.0)');
        grd.addColorStop(0.45, 'rgba(255,255,255,0.1)');
        grd.addColorStop(0.5, 'rgba(255,255,255,1.0)');
        grd.addColorStop(0.55, 'rgba(255,255,255,0.55)');
        grd.addColorStop(0.6, 'rgba(255,255,255,0.25)');
        grd.addColorStop(0.8, 'rgba(255,255,255,0.15)');
        grd.addColorStop(1, 'rgba(255,255,255,0)');

        context.fillStyle = grd;
        context.fillRect(0, scanOffsetY, canvas.width, scanSize + scanOffsetY);
        context.globalCompositeOperation = "lighter";

        scanOffsetY += (canvas.height / scanSpeed);
        if (scanOffsetY > canvas.height) scanOffsetY = -(scanSize / 2);

        window.requestAnimationFrame(render);
      }
      window.onresize();
      window.requestAnimationFrame(render);
    })();

  }, 1000);
}

