import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MapView from './MapView';
import './App.css';
import Loading from './Loading';
import SocialLinks from './SocialLinks';
import axios from 'axios';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [age, setAge] = useState(calculateAge());
  const [config, setConfig] = useState(null); 

  const fetchConfig = async () => {
      try {
          const response = await axios.get('/api/config');  
          await setConfig(response.data);
          return response.data;
      } catch (error) {
        console.error('Error fetching config:', error);
      }
  }

  // Simulate loading delay
  useEffect(() => {
    fetchConfig().then(config => {
      if (config.maintenance !==  "true") {
        const timer = setTimeout(() => {
          setLoading(false);
        }, 13000); // Show loading screen for 13 seconds
        return () => clearTimeout(timer);  
      }
  
    })
  }, []);

  if (!config) {
    return;
  }
  if (loading) {
    return <Loading maintenance={config.maintenance} />;
  }

  function calculateAge() {
    const birthDate = new Date('August 21, 1980');
    const currentDate = new Date();
    const ageInMilliseconds = currentDate - birthDate;
    const ageInYears = Math.floor(ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25));
    return ageInYears;
  }

  return (
    <Container className='container crt noselect'>
      <Header>
        <ProfileImage src="user.png" alt="Profile" />
        <ProfileInfo>
          <Name>SHAKHAL</Name>
          <Title>HUMAN, {age} </Title>
        </ProfileInfo>
      </Header>

      <Bio>
        <h2>ABOUT</h2>
        <p>
        Born on August 21, 1980, User emerged into the world just in time for the dawn of personal computing — probably thinking, "What's with all these blinking lights?" Equipped with an impressive set of opposable thumbs and a knack for solving complex problems, quickly became adept at typing furiously at a keyboard while simultaneously avoiding paper cuts.
        </p>
      </Bio>

      <MapSection>
        <MapView />
      </MapSection>
      <SocialLinks/>
    </Container>
  );
};

export default App;

// Styled Components with Cryptic 80s PC Theme

const Container = styled.div`
  font-family: 'Courier New', Courier, monospace;
  color: #33ff33;
  background-color: #000000;
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  border: 2px solid #33ff33;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 20px;
  border: 2px solid #33ff33;
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 20px;
  border: 2px solid #33ff33;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.h1`
  font-size: 2rem;
  margin: 0;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  color: #33ff33;
  margin-top: 5px;
`;

const Bio = styled.section`
  padding: 20px;
  margin-top: 20px;
  border: 2px solid #33ff33;

  h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #33ff33;
  }
`;

const MapSection = styled.section`
  padding: 20px;
  margin-top: 20px;
  border: 2px solid #33ff33;

  h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
`;