import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { printLines, typeLine } from './Printer';

// The content to simulate ChatGPT "writing"
const loadingLines = [
  "Trace program: running",
  "Initializing engine...",
  "Establishing secure connection...",
  "Loading knowledge base...",
  "Checking GPS signal...",
  "Locating target...",
  "Triangulating coordinates...",
  "Rendering visuals..."
]

const maintainanceLines = [
  "(\\(\\          ",
  "( -.-)         ",
  "o_(\")(\")      ",
  "",
  "Oops! ",
  "Our bunny is busy",
  "Tidying things up!",
  "Doing some spring cleaning 🧹",  
  "Digging a new burrow! 🐇",
  "Organizing carrots! 🥕🥕🥕🥕🥕🥕",
  "Collecting shiny pebbles 💎💎💎",
  "Knitting a cozy sweater 🧶",
  "Sharpening teeth! 🦷",
  "Taking a nap 💤",
  "Hopping around! 🐇",
  "Chasing butterflies! 🦋",
  "Playing hide and seek! 🙈",
  "Sniffing flowers! 🌸",
  "Polishing ears 🧼",
  "Try again later... 🕒",
]

const Loading = (props) => {
  const [displayText, setDisplayText] = useState('');
  
  useEffect(() => {
    // Start printing lines
    if (props.maintenance === "true") {
      printLines(maintainanceLines, setDisplayText, 1000)
      .then(() => {
        typeLine('\n', setDisplayText);
      });
    }
    else {
      printLines(loadingLines, setDisplayText, 1000);
    }
  
  }, []);

  return (
    <LoadingContainer className='crt noselect'>
      <TypingArea>
        <Text>
          {displayText}
          {<Cursor>■</Cursor>}
        </Text>
      </TypingArea>
    </LoadingContainer>
  );
};

export default Loading;

// Blinking cursor animation (keyframes)
const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

// Styled components for the loading screen

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000000;
  color: #33ff33;
  font-family: 'Courier New', Courier, monospace;
`;

const TypingArea = styled.div`
  font-size: 18px;
  white-space: pre-wrap; // Ensures newlines and formatting are kept
  font-family: 'Courier New', Courier, monospace;
  width: 370px
`;

const Text = styled.span`
  font-size: 18px;
`;

const Cursor = styled.span`
  font-size: 18px;
  color: #33ff33;
  animation: ${blink} 0.3s infinite;
`;
